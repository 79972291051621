import React, {useEffect} from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import {useForm} from "react-hook-form";
import {loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha} from 'react-simple-captcha';

const EngineeringQuestionnaireForm = () => {
    const {register, handleSubmit, reset, formState: {errors}} = useForm();

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);

    const onSubmit = data => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        if (!validateCaptcha(user_captcha_value) === true) {
            alert('Captcha Does Not Match');
            return
        }

        let formdata = new FormData();
        formdata.append("isMember", data.isMember);
        formdata.append("requestReason", data.requestReason);
        formdata.append("username", data.name);
        formdata.append("mailAddress", data.mailAddress);
        formdata.append("propertyAddress", data.propertyAddress);
        formdata.append("address911", data.address911);
        formdata.append("ruralAddress", data.ruralAddress);
        formdata.append("residentialBusinessType", data.residentialBusinessType);
        formdata.append("commercialBusinessType", data.commercialBusinessType);
        formdata.append("homePhoneNumber", data.homePhoneNumber);
        formdata.append("cellPhone", data.cellPhone);
        formdata.append("workPhoneNumber", data.workPhoneNumber);
        const requestOptions = {
            method: 'POST',
            body: formdata,
        };

        fetch(`${process.env.GATSBY_API_URL}wp-json/contact-form-7/v1/contact-forms/498/feedback`, requestOptions)
            .then(response => response.json())
            .then(() => {
                alert('Form submitted successfully.')
                reset();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <Layout>
            <Seo title="Engineering Questionnaire" description="SEIEC Engineering Questionnaire"/>
            <div className="w-full px-8">
                <h2 className="item-title">Engineering Questionnaire</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="overflow-hidden sm:rounded-md">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="isMember" className="block text-sm font-medium text-gray-700">
                                    Are you already a member?
                                </label>
                                <input
                                    id="yes-member"
                                    name="isMember"
                                    type="radio"
                                    value="yes"
                                    {...register("isMember", {required: true})}
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                />
                                <label htmlFor="yes-member"
                                       className="ml-2 block text-sm font-medium text-gray-700 inline-block mr-4">Yes</label>
                                <input
                                    id="no-member"
                                    name="isMember"
                                    type="radio"
                                    value="no"
                                    {...register("isMember", {required: true})}
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                />
                                <label htmlFor="no-member"
                                       className="ml-2 block text-sm font-medium text-gray-700 inline-block mr-4">No</label>
                                {errors.isMember?.type === 'required' &&
                                <span className="error text-red">Member selection is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="requestReason" className="block text-sm font-medium text-gray-700">
                                    Please Select the Reason for Your Request
                                </label>
                                <select
                                    id="requestReason"
                                    name="requestReason"
                                    {...register("requestReason", {required: true})}
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    <option value="">--Please Select--</option>
                                    <option value="Applying For Service">Applying For Service</option>
                                    <option value="Applying For Security Light">Applying For Security Light</option>
                                    <option value="I'd Like to Report a Security Light Outage">I'd Like to Report a
                                        Security Light Outage
                                    </option>
                                </select>
                                {errors.isMember?.type === 'required' &&
                                <span className="error text-red">Reason for Your Request is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    {...register("name", {required: true, maxLength: 20})}
                                    name="name"
                                    id="name"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.name?.type === 'required' &&
                                <span className="error text-red">Name is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="mailAddress" className="block text-sm font-medium text-gray-700">
                                    Mail Address
                                </label>
                                <input
                                    type="text"
                                    name="mailAddress"
                                    {...register("mailAddress", {required: true})}
                                    id="mailAddress"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.mailAddress?.type === 'required' &&
                                <span className="error text-red">Mail Address is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="propertyAddress" className="block text-sm font-medium text-gray-700">
                                    Property Address (service address):
                                </label>
                                <input
                                    type="text"
                                    name="propertyAddress"
                                    {...register("propertyAddress", {required: true})}
                                    id="propertyAddress"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.propertyAddress?.type === 'required' &&
                                <span className="error text-red">Property Address is required</span>}
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="address911" className="block text-sm font-medium text-gray-700">
                                    911 Address (if at all possible):
                                </label>
                                <input
                                    type="text"
                                    name="address911"
                                    {...register("address911")}
                                    id="address911"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>

                            <div className="col-span-6">
                                <label htmlFor="ruralAddress" className="block text-sm font-medium text-gray-700">
                                    Rural Route Address and Description of Location if no 911 Address:
                                </label>
                                <input
                                    type="text"
                                    name="ruralAddress"
                                    {...register("ruralAddress")}
                                    id="ruralAddress"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="col-span-6">
                                <p><strong>If new service, what is it going to?</strong></p>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="residentialBusinessType"
                                       className="block text-sm font-medium text-gray-700">
                                    Examples: If residential (house, single wide mobile home or double wide mobile home,
                                    barn or shop):
                                </label>
                                <input
                                    type="text"
                                    name="residentialBusinessType"
                                    {...register("residentialBusinessType")}
                                    id="residentialBusinessType"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="commercialBusinessType"
                                       className="block text-sm font-medium text-gray-700">
                                    If commercial, roughly describe business type:
                                </label>
                                <input
                                    type="text"
                                    name="commercialBusinessType"
                                    {...register("commercialBusinessType")}
                                    id="commercialBusinessType"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>

                            <div className="col-span-6">
                                <p><strong>Contact Information</strong></p>
                            </div>

                            <div className="col-span-6 sm:col-span-2">
                                <label htmlFor="homePhoneNumber" className="block text-sm font-medium text-gray-700">
                                    Home Phone Number
                                </label>
                                <input
                                    type="text"
                                    name="homePhoneNumber"
                                    {...register("homePhoneNumber")}
                                    id="homePhoneNumber"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-2">
                                <label htmlFor="cellPhone" className="block text-sm font-medium text-gray-700">
                                    Cell Phone
                                </label>
                                <input
                                    type="text"
                                    name="cellPhone"
                                    {...register("cellPhone")}
                                    id="cellPhone"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-2">
                                <label htmlFor="workPhoneNumber" className="block text-sm font-medium text-gray-700">
                                    Work Phone Number
                                </label>
                                <input
                                    type="text"
                                    name="workPhoneNumber"
                                    {...register("workPhoneNumber")}
                                    id="workPhoneNumber"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="col-span-6 sm:col-span-2">
                                <label htmlFor="workPhoneNumber" className="block text-sm font-medium text-gray-700">
                                    Enter text in field below for validation.
                                </label>
                                <LoadCanvasTemplate/>
                                <input
                                    type="text"
                                    name="captcha"
                                    {...register("captcha", {required: true})}
                                    id="user_captcha_input"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.captcha?.type === 'required' &&
                                <span className="error text-red">Captcha is required</span>}
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Send
                        </button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default EngineeringQuestionnaireForm
